/**
 * 处理后台获取的分类数据为合格的树状数据格式
 */
export function disposeTreeData(arr = [],level = 3){
  try {
    arr = JSON.parse(JSON.stringify(arr));
  }catch (e) {

  }
  let props = {
    label:'name',
    children:'children',
    disabled:'disabled',
    isLeaf:'isLeaf',
    leaf:'leaf',
  }
  let one = arr.filter(res=>res.parentId === "0");

  if(level>1){
    let oneId = one.map(res=>res.id);
    let two = arr.filter(res=>oneId.includes(res.parentId))
    one.forEach((item,index)=>{
      let id = item.id
      let children = two.filter(res=>res.parentId === id)
      item[props.children] = children
    });
    if(level>2){
      let twoId = two.map(res=>res.id)
      let three = arr.filter(res=>twoId.includes(res.parentId))
      two.forEach((item,index)=>{
        let id = item.id
        let children = three.filter(res=>res.parentId === id)
        item[props.children] = children
      });
    }
  }
  return one;
}
